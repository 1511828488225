import { Types } from 'src/generated/types';
import { mainRouters } from './main';
import { Navigate } from 'react-router-dom';
import useRoutesWithPermission from '../hooks/use-routes-with-permission';

export default function Router() {
  const routers: Types.RouteObject[] = [
    ...mainRouters,
    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ];
  return useRoutesWithPermission(routers, []);
}
