import { ReactNode } from 'react';
import { MainFooter, MainHeader } from './components';

type Props = {
  children?: ReactNode;
};

export default function MainLayout({ children }: Props) {
  return (
    <div className="flex-1">
      <MainHeader />
      <div className="flex-1">{children}</div>
      <MainFooter />
    </div>
  );
}
