import ImageAssets from 'src/assets/images';
import { RouterLink } from 'src/routes/components';

const NAVS = [
  { title: 'ផលិតផល', href: '/' },
  { title: 'របៀបលេង', href: '/' },
  { title: 'កន្លែងទិញឆ្នោត', href: '/' },
  { title: 'សម្រាប់ភ្នាក់ងារលក់', href: '/' },
  { title: 'អំពីក្រុមហ៊ុន', href: '/about-us' },
];

export default function MainHeader() {
  return (
    <div className="h-14 lg:max-h-16 justify-center bg-primary px-0 md:px-6">
      <div className="container flex-row items-center self-center max-w-screen-xl justify-center md:justify-normal">
        <RouterLink href="/" className="self-center h-14 lg:max-h-16">
          <img src={ImageAssets.Logo} className="h-full w-fit" />
        </RouterLink>
        <div className="flex-1 hidden md:flex"></div>
        <div className="flex-row items-center hidden md:flex">
          {NAVS.map((nav, i) => (
            <RouterLink
              key={i}
              href={nav.href}
              style={{ textShadow: '1px 1px 2px #232323' }}
              className="text-white px-2 lg:px-4 text-base lg:text-xl hover:scale-105 hover:text-[#ff0]"
            >
              {nav.title}
            </RouterLink>
          ))}
        </div>
      </div>
    </div>
  );
}
