import { CSSProperties } from 'react';
import ImageAssets from 'src/assets/images';
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';

const InfoLabel = ({
  title,
  style,
  className,
}: {
  title: string;
  className?: string;
  style?: CSSProperties;
}) => {
  return (
    <label
      className={`mb-1 text-center md:text-start text-[#333] px-1 ${className || ''}`}
      style={style}
    >
      {title}
    </label>
  );
};

export default function MainFooter() {
  return (
    <div className="flex-row justify-center bg-[#dcbb6c] pt-2 pb-4 md:py-2 px-0 md:px-6">
      <div className="container flex-col max-w-screen-xl">
        <div className="flex-col md:flex-row items-center md:items-start justify-normal md:justify-between mb-1">
          <div className="items-center md:items-start w-auto md:max-w-md">
            <img src={ImageAssets.Logo} className="h-fit w-40 lg:w-44" />
            <InfoLabel
              title="Lucky Star Dragon Lotto Address"
              style={{ color: '#FFF', textShadow: '#000 1px 1px 2px, #000 1px 1px 2px' }}
            />
            <InfoLabel
              title="Address: #688 National Road 2, Sangkat Chak Angre Krom Khan Meanchey, Phnom Penh,
              Cambodia."
            />
          </div>
          <div className="items-center md:items-start w-auto md:max-w-md mt-5">
            <InfoLabel
              title="Lucky Star Dragon Lotto info"
              className="text-white"
              style={{ color: '#FFF', textShadow: '#000 1px 1px 2px, #000 1px 1px 2px' }}
            />
            <InfoLabel title="Hotline: 023 966 639" />
            <InfoLabel title="info@luckydragonlotto.com" />
            <RouterLink
              target="_blank"
              className="flex flex-row items-center"
              href="https://maps.app.goo.gl/W5sBsK483xQF6TNv8"
            >
              <Iconify
                icon="gis:poi-map"
                className="mb-1 text-primary self-center mr-1.5 cursor-pointer"
              />
              <label className="text-primary cursor-pointer">Lucky Dragon Lotto Head Office</label>
            </RouterLink>
          </div>
        </div>
        <label
          className=" mt-1 mb-2 text-white self-center"
          style={{ textShadow: '#000 0px 0px 2px, #000 0px 0px 1px' }}
        >
          រក្សាសិទ្ធិ© គ្រប់យ៉ាងដោយ ឆ្នោតនាគនាំសំណាង
        </label>
      </div>
    </div>
  );
}
