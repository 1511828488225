import React from 'react';
import Router from './routes/routers';

function App() {
  return (
    <div className="flex-1 bg-white">
      <Router />
    </div>
  );
}

export default App;
