import { Types } from 'src/generated/types';
import { useRoutes, RouteObject } from 'react-router-dom';

function RoutesWithPermission(
  route: Types.RouteObject | null,
  permissions: string[]
): Types.RouteObject | null {
  const children = route?.children || [];
  const hasPermission =
    children.length === 0 &&
    (!route?.permissions || route?.permissions.filter((f) => permissions.includes(f)).length > 0);
  if (children.length > 0) {
    const newChildren = children
      .map((m) => RoutesWithPermission(m, permissions))
      .filter((f) => !!f);
    return newChildren.length > 0 ? { ...route, children: newChildren || undefined } : null;
  }
  return hasPermission ? { ...route, children: undefined } : null;
}

export default function useRoutesWithPermission(
  routes: Types.RouteObject[],
  permissions: string[]
) {
  return useRoutes(routes.map((m) => RoutesWithPermission(m, permissions)) as RouteObject[]);
}
